body {
  background-color: aliceblue;
  padding: 0px;
  margin: 0px;
}

.loading-screen {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: xx-large;
}
